import React, { useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import LayoutDashboard from './layouts/dashboard';
import DashboardHome from './modules/dashboard/home';
import NoMatch from './modules/404/index';
import Login from './modules/login/index';
import Permission from './modules/dashboard/permissions/index';
import Group from './modules/dashboard/group/index';
import User from './modules/dashboard/user/index';
import Document from './modules/dashboard/documenttype/index';
import CodePromo from './modules/dashboard/codepromo/index';
import InvitationCode from './modules/dashboard/admin/invitationcode/index';
import PackageFeature from './modules/dashboard/packageFeature/index';
import PackageType from './modules/dashboard/packageType/index';
import PackageTypeDetail from './modules/dashboard/packageType/detail';
import WaitingList from './modules/dashboard/admin/waitinglist/waitinglist'
import Client from './modules/dashboard/client/index';
import { ToastContainer } from 'react-toastify';
//import './assets/notification.css';
import 'react-toastify/dist/ReactToastify.css';
import PermissionsContext from './contexts/permissionContext';

// Détail client V2
import ClientDetailV2 from './modules/dashboard/client/detail/index';
import ClientDetailV2Security from './modules/dashboard/client/detail/security';
import ClientDetailV2Membership from './modules/dashboard/client/detail/membership';
import ClientDetailV2Wallets from './modules/dashboard/client/detail/wallets';
import ClientDetailV2Cards from './modules/dashboard/client/detail/cards';
import ClientDetailV2Operations from './modules/dashboard/client/detail/operations';
import ClientDetailV2Communication from './modules/dashboard/client/detail/communication';

import ClientDetail from './modules/dashboard/client/client_detail';
import ClientInformation from './modules/dashboard/client/information';
import ClientMembership from './modules/dashboard/client/membership';
import ClientPackage from './modules/dashboard/client/package';
import ClientDocument from './modules/dashboard/client/document';
import ClientHistory from './modules/dashboard/client/history';
import ClientHistoryHttp from './modules/dashboard/client/historyHttp';
import ClientCards from './modules/dashboard/client/card/cards';
import ClientWallet from './modules/dashboard/client/wallet';
import ClientWalletCardDetail from './modules/dashboard/client/card/card_detail';
import ClientWalletBalanceHistory from './modules/dashboard/client/wallet/balanceHistory';
import ClientWalletLineDetail from './modules/dashboard/client/walletline/walletline';
import ClientBeneficiary from './modules/dashboard/client/beneficiary';
import ClientScaWallets from './modules/dashboard/client/sca/sca_wallets';
import ClientMandate from './modules/dashboard/client/mandates';
import ClientScheduledTransfers from './modules/dashboard/client/transfers/scheduledtransfers';
import ClientTopupCards from './modules/dashboard/client/topupcards/topupcards';
import ClientKYCLiveness from './modules/dashboard/client/kycliveness';
import ClientNotifEvent from './modules/dashboard/client/historyNotifEvent';
import ClientBridgeHistory from './modules/dashboard/client/bridge';
import ClientBillingsHistory from './modules/dashboard/client/billings';
import ClientStatements from './modules/dashboard/client/statements';
import ClientGodchildren from './modules/dashboard/client/godchildren';
import ClientMembers from './modules/dashboard/client/members/members';
import ClientSearchByIds from './modules/dashboard/client_search/index';
import ClientSearchByCodePromo from './modules/dashboard/client_by_code_promo/index';
import ClientSearchByStatus from './modules/dashboard/client_status/index';
import ClientSepasSddrRejected from './modules/dashboard/client/sepasddrrejected'
import ClientIsicCards from './modules/dashboard/client/isic/isic_cards';
import Partnership from './modules/dashboard/partnership/index'
import SupervisionCenter from './modules/dashboard/supervision/supervision'
import PayinAlerts from './modules/dashboard/supervision/payinalerts'
import SepasSddrRejected from './modules/dashboard/supervision/sepasddrrejected'
import ClientFraudSupected from './modules/dashboard/supervision/client_fraud_suspected';
import ClientBillingsToRecover from './modules/dashboard/supervision/client_to_recover/index';
import ClientWalletOverdraft from './modules/dashboard/supervision/client_overdraft/index';
import BusinessReports from './modules/dashboard/reports/reports'
import AdministrationCenter from './modules/dashboard/admin/admin'
import StatusCenter from './modules/dashboard/status/status'

/*
Allows to select the layout for the pages
It provides convenience to write conditions according to layout type
*/
export const withLayout = (Layout, Component) => {
  return (
    <Layout>
      <Component />
    </Layout>
  );
};

const App = () => {
  const [permContext, setPermContext] = useState();
  const permissionsValue = { permContext, setPermContext };
  return (
    <PermissionsContext.Provider value={permissionsValue}>
        <ToastContainer />
        <BrowserRouter>
          <Routes>
            <Route exact path="/dashboard" element={withLayout(LayoutDashboard, DashboardHome)}/>
            <Route exact path="/dashboard/admin/permissions" element={withLayout(LayoutDashboard, Permission)}/>
            <Route exact path="/dashboard/admin/groups" element={withLayout(LayoutDashboard, Group)}/>
            <Route exact path="/dashboard/admin/users" element={withLayout(LayoutDashboard, User)}/>
            <Route exact path="/dashboard/admin/documents" element={withLayout(LayoutDashboard, Document)}/>
            <Route exact path="/dashboard/admin/codepromos" element={withLayout(LayoutDashboard, CodePromo)}/>
            <Route exact path="/dashboard/admin/invitationcodes" element={withLayout(LayoutDashboard, InvitationCode)}/>
            <Route exact path="/dashboard/admin/package-features" element={withLayout(LayoutDashboard, PackageFeature)}/>
            <Route exact path="/dashboard/admin/package-types" element={withLayout(LayoutDashboard, PackageType)}/>
            <Route exact path="/dashboard/admin/package-types/:id" element={withLayout(LayoutDashboard, PackageTypeDetail)}/>
            <Route exact path="/dashboard/admin/waitinglist" element={withLayout(LayoutDashboard, WaitingList)}/>
            <Route exact path="/dashboard/client" element={withLayout(LayoutDashboard, Client)}/>

            <Route exact path="/dashboard/client_v2/:id" element={withLayout(LayoutDashboard, ClientDetailV2)}/>
            <Route exact path="/dashboard/client_v2/:id/security" element={withLayout(LayoutDashboard, ClientDetailV2Security)}/>
            <Route exact path="/dashboard/client_v2/:id/membersip" element={withLayout(LayoutDashboard, ClientDetailV2Membership)}/>
            <Route exact path="/dashboard/client_v2/:id/wallets" element={withLayout(LayoutDashboard, ClientDetailV2Wallets)}/>
            <Route exact path="/dashboard/client_v2/:id/cards" element={withLayout(LayoutDashboard, ClientDetailV2Cards)}/>
            <Route exact path="/dashboard/client_v2/:id/operations" element={withLayout(LayoutDashboard, ClientDetailV2Operations)}/>
            <Route exact path="/dashboard/client_v2/:id/communication" element={withLayout(LayoutDashboard, ClientDetailV2Communication)}/>

            <Route exact path="/dashboard/client/:id" element={withLayout(LayoutDashboard, ClientDetail)}/>
            <Route exact path="/dashboard/client/:id/information" element={withLayout(LayoutDashboard, ClientInformation)}/>
            <Route exact path="/dashboard/client/:id/membership" element={withLayout(LayoutDashboard, ClientMembership)}/>
            <Route exact path="/dashboard/client/:id/package" element={withLayout(LayoutDashboard, ClientPackage)}/>
            <Route exact path="/dashboard/client/:id/document" element={withLayout(LayoutDashboard, ClientDocument)}/>
            <Route exact path="/dashboard/client/:id/history" element={withLayout(LayoutDashboard, ClientHistory)}/>
            <Route exact path="/dashboard/client/:id/history/http" element={withLayout(LayoutDashboard, ClientHistoryHttp)}/>
            <Route exact path="/dashboard/client/:id/cards" element={withLayout(LayoutDashboard, ClientCards)}/>
            <Route exact path="/dashboard/client/:id/wallet/:walletId" element={withLayout(LayoutDashboard, ClientWallet)}/>
            <Route exact path="/dashboard/client/:userId/wallet/:walletId/balance/history" element={withLayout(LayoutDashboard, ClientWalletBalanceHistory)}/>
            <Route exact path="/dashboard/client/:userId/wallet/:walletId/line/:walletlineId" element={withLayout(LayoutDashboard, ClientWalletLineDetail)}/>
            <Route exact path="/dashboard/client/:userId/wallet/:walletId/card/:cardId" element={withLayout(LayoutDashboard, ClientWalletCardDetail)}/>
            <Route exact path="/dashboard/client/:id/sca" element={withLayout(LayoutDashboard, ClientScaWallets)}/>
            <Route exact path="/dashboard/client/:id/beneficiary" element={withLayout(LayoutDashboard, ClientBeneficiary)}/>
            <Route exact path="/dashboard/client/:id/sddr_rejected" element={withLayout(LayoutDashboard, ClientSepasSddrRejected)}/>
            <Route exact path="/dashboard/client/:id/mandate" element={withLayout(LayoutDashboard, ClientMandate)}/>
            <Route exact path="/dashboard/client/:id/billings" element={withLayout(LayoutDashboard, ClientBillingsHistory)}/>
            <Route exact path="/dashboard/client/:id/statements" element={withLayout(LayoutDashboard, ClientStatements)}/>
            <Route exact path="/dashboard/client/:id/scheduledtransfers" element={withLayout(LayoutDashboard, ClientScheduledTransfers)}/>
            <Route exact path="/dashboard/client/:id/topupcards" element={withLayout(LayoutDashboard, ClientTopupCards)}/>
            <Route exact path="/dashboard/client/:id/godchildren" element={withLayout(LayoutDashboard, ClientGodchildren)}/>
            <Route exact path="/dashboard/client/:id/members" element={withLayout(LayoutDashboard, ClientMembers)}/>
            <Route exact path="/dashboard/client/:id/kycliveness" element={withLayout(LayoutDashboard, ClientKYCLiveness)}/>
            <Route exact path="/dashboard/client/:id/events/history" element={withLayout(LayoutDashboard, ClientNotifEvent)}/>
            <Route exact path="/dashboard/client/:id/bridge/history" element={withLayout(LayoutDashboard, ClientBridgeHistory)}/>
            <Route exact path="/dashboard/client/:userId/isic_cards" element={withLayout(LayoutDashboard, ClientIsicCards)}/>
            <Route exact path="/dashboard/client_search" element={withLayout(LayoutDashboard, ClientSearchByIds)}/>
            <Route exact path="/dashboard/client_by_code_promo" element={withLayout(LayoutDashboard, ClientSearchByCodePromo)}/>
            <Route exact path="/dashboard/partnership" element={withLayout(LayoutDashboard, Partnership)}/>
            <Route exact path="/dashboard/clientbystatus/:status" element={withLayout(LayoutDashboard, ClientSearchByStatus)}/>
            <Route exact path="/dashboard/reports/reports" element={withLayout(LayoutDashboard, BusinessReports)}/>
            <Route exact path="/dashboard/admin" element={withLayout(LayoutDashboard, AdministrationCenter)}/>
            <Route exact path="/dashboard/supervision" element={withLayout(LayoutDashboard, SupervisionCenter)}/>
            <Route exact path="/dashboard/supervision/client_fraud_suspected" element={withLayout(LayoutDashboard, ClientFraudSupected)}/>
            <Route exact path="/dashboard/supervision/payin_alerts" element={withLayout(LayoutDashboard, PayinAlerts)}/>
            <Route exact path="/dashboard/supervision/sddr_rejected" element={withLayout(LayoutDashboard, SepasSddrRejected)}/>
            <Route exact path="/dashboard/supervision/client_to_recover" element={withLayout(LayoutDashboard, ClientBillingsToRecover)}/>
            <Route exact path="/dashboard/supervision/client_overdraft" element={withLayout(LayoutDashboard, ClientWalletOverdraft)}/>
            <Route exact path="/dashboard/status" element={withLayout(LayoutDashboard, StatusCenter)}/>
            <Route exact path="/" element={<Login />}/>
            <Route element={<NoMatch />}/>
          </Routes>
        </BrowserRouter>
    </PermissionsContext.Provider>
  );
};

export default App;
