import { backendApiEndpoint } from '../../constants.js';
import { CheckAuth, instanceBackendApi } from '../../index';
import { toast } from 'react-toastify';
var qs = require('qs');

export async function scaWalletListReq(userId) {
  try {
    const res = await instanceBackendApi.get(
      backendApiEndpoint.client_sca_wallet_list.replace('{{userId}}', userId),
    );
    if (res.status === 200) {
      return res.data;
    } else {
      return 'fail';
    }
  } catch (error) {
    toast.error('Une erreur est survenue!' + error.response.data?.error?.message);
    CheckAuth(error);
    return 'fail';
  }
}

export async function scaWalletDetailReq(userId, scaWalletId, force) {
  try {
    let queryParams = {
      params: {
        force: force
      }
    };
    const res = await instanceBackendApi.get(backendApiEndpoint.client_sca_wallet_detail.replace('{{userId}}', userId).replace('{{scaWalletId}}', scaWalletId), queryParams);
    if (res.status === 200) {
      return res.data;
    } else {
      return 'fail';
    }
  } catch (error) {
    toast.error('Une erreur est survenue!' + error.response.data?.error?.message);
    CheckAuth(error);
    return 'fail';
  }
}

export async function updateScaWalletLockReq(userId, scaWalletId) {

  try {
    const res = await instanceBackendApi.put(backendApiEndpoint.client_sca_wallet_lock.replace('{{userId}}', userId).replace('{{scaWalletId}}', scaWalletId));
    if (res.status === 200) {
      toast('Blocage du SCA Wallet enregistrée !');
      return 'success';
    } else {
      toast.error('Une erreur est survenue !');
      return 'fail';
    }
  } catch (error) {
    toast.error('Une erreur est survenue ! Error Code = ' + error.response.data?.message);
    CheckAuth(error)
    return 'fail';

  }
}

export async function updateScaWalletUnlockReq(userId, scaWalletId) {

  try {
    const res = await instanceBackendApi.put(backendApiEndpoint.client_sca_wallet_unlock.replace('{{userId}}', userId).replace('{{scaWalletId}}', scaWalletId));
    if (res.status === 200) {
      toast('Blocage du SCA Wallet enregistrée !');
      return 'success';
    } else {
      toast.error('Une erreur est survenue !');
      return 'fail';
    }
  } catch (error) {
    toast.error('Une erreur est survenue ! Error Code = ' + error.response.data?.message);
    CheckAuth(error)
    return 'fail';

  }
}

export async function updateScaWalletResetPinReq(userId, scaWalletId) {

  try {
    const res = await instanceBackendApi.put(backendApiEndpoint.client_sca_wallet_resetpin.replace('{{userId}}', userId).replace('{{scaWalletId}}', scaWalletId));
    if (res.status === 200) {
      toast('Déblocage de la SCA Wallet avec succès');
      return 'success';
    } else {
      toast.error('Une erreur est survenue!');
      return 'fail';
    }
  } catch (error) {
    toast.error('Une erreur est survenue! Error Code = ' + error.response.data?.error?.message);
    CheckAuth(error)
    return 'fail';

  }
}


export async function deleteScaWalletReq(userId, scaWalletId) {

  try {
    const res = await instanceBackendApi.delete(backendApiEndpoint.client_sca_wallet_detail.replace('{{userId}}', userId).replace('{{scaWalletId}}', scaWalletId));
    if (res.status === 204) {
      toast('Suppression du SCA Wallet avec succès');
      return 'success';
    } else {
      toast.error('Une erreur est survenue!');
      return 'fail';
    }
  } catch (error) {
    toast.error('Une erreur est survenue! Error Code = ' + error.response.data?.error?.message);
    CheckAuth(error)
    return 'fail';

  }
}
